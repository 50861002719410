import React from 'react';
import {useTranslation} from 'react-i18next';

import {Icon, ICON_NAMES} from '@nur-portal/ui-kit';

import styles from './empty-bookmarks.module.css';

const EmptyBookmarks = () => {
  const {t} = useTranslation('bookmarks');

  return (
    <div className={styles.emptyContainer}>
      <div className={styles.topIconContainer}>
        <Icon name={ICON_NAMES.BOOKMARK_ON_24} className={styles.topIcon} />
      </div>
      <div className={styles.title}>
        {t('emptyList.title')}
      </div>
      <div className={styles.description}>
        {t('emptyList.description1')}{' '}
        «<Icon name={ICON_NAMES.BOOKMARK_OFF_24} className={styles.icon} />»{' '}
        {t('emptyList.description2')}
      </div>
    </div>
  );
};

export {EmptyBookmarks};
