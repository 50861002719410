import React from 'react';
import clsx from 'clsx';

import {ARTICLE_CARD_VARIANTS, ICON_NAMES} from '@nur-portal/ui-kit';

const ArticleCard = ({
  onClick,
  onRemoveArticle,
  className,
  formattedDate,
  variant = ARTICLE_CARD_VARIANTS.PRIMARY,
  image,
  articleBadgeComponent,
  title,
  link,
  date,
  id
}) => (
  <article
    data-id={id}
    className={clsx([
      'article-card',
      className,
      {
        'article-card--primary': variant === ARTICLE_CARD_VARIANTS.PRIMARY,
        'article-card--secondary': variant === ARTICLE_CARD_VARIANTS.SECONDARY,
        'article-card--tertiary': variant === ARTICLE_CARD_VARIANTS.TERTIARY,
        'article-card--video': variant === ARTICLE_CARD_VARIANTS.VIDEO,
        'article-card--list': variant === ARTICLE_CARD_VARIANTS.LIST,
        'article-card--horizontal-small': variant === ARTICLE_CARD_VARIANTS.HORIZONTAL_SMALL,
        'article-card--all-news': variant === ARTICLE_CARD_VARIANTS.ALL_NEWS,
        'article-card--horizontal-large-dark': variant === ARTICLE_CARD_VARIANTS.HORIZONTAL_LARGE_DARK,
        'article-card--horizontal-large-light': variant === ARTICLE_CARD_VARIANTS.HORIZONTAL_LARGE_LIGHT,
        'article-card--recipe-secondary': variant === ARTICLE_CARD_VARIANTS.RECIPE_SECONDARY,
        'article-card--recipe-tertiary': variant === ARTICLE_CARD_VARIANTS.RECIPE_TERTIARY,
        'article-card--recipe-list': variant === ARTICLE_CARD_VARIANTS.RECIPE_LIST
      }
    ])}
  >
    {image && (
      <picture className="article-card__image-container">
        {onRemoveArticle && (
          <button onClick={() => onRemoveArticle(id)} className="article-card__remove-button">
            <svg className="article-card__remove-icon">
              <use href={`#${ICON_NAMES.CLOSE_24}`} />
            </svg>
          </button>
        )}
        <source type="image/webp" sizes={image.sizes} srcSet={image.srcset.webp} />
        <img
          className="article-card__image"
          sizes={image.sizes}
          srcSet={image.srcset.jpeg}
          src={image.src.jpeg}
          alt={image.alt}
          title={image.name}
          loading={image.loading}
          decoding="async"
        />
      </picture>
    )}
    {articleBadgeComponent && <div className="article-card__badge">{articleBadgeComponent}</div>}
    <a className="article-card__title" href={link} onClick={event => onClick && onClick(event)}>
      {title}
    </a>
    {formattedDate && (
      <time className="article-card__date" dateTime={date}>
        {formattedDate}
      </time>
    )}
    <div className="card-meta article-card__count-block">
      <div className="card-meta__item js-article-preview-comments hidden">
        <svg className="card-meta__icon">
          <use href="#COMMENTS_18" />
        </svg>
        <div className="card-meta__text js-article-preview-comments-count" />
      </div>
      <div className="card-meta__item js-article-preview-reactions hidden">
        <svg className="card-meta__icon">
          <use href={`#${ICON_NAMES.REACTIONS_18}`} />
        </svg>
        <div className="card-meta__text js-article-preview-reactions-count" />
      </div>
    </div>
  </article>
);

export {ArticleCard};
