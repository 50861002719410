import {customFetch} from '../../website-ui/utils/fetch';

import {articlesMapper} from './articles-mapper';
import {BOOKMARK_CUSTOM_EVENTS, BOOKMARK_IDS} from './constants';

const shallowMergeArrays = (arr1, arr2) => {
  const map = new Map();

  [...arr1, ...arr2].forEach(obj => {
    if (map.has(obj.id)) {
      map.set(obj.id, {...map.get(obj.id), ...obj});
    } else {
      map.set(obj.id, {...obj});
    }
  });

  return Array.from(map.values());
};

class BookmarksService {
  hasBookmark(bookmarkId) {
    const bookmarks = this.getBookmarks();

    return Boolean(bookmarks.find(bookmark => bookmark.id === Number(bookmarkId)));
  }

  getBookmarks() {
    try {
      const rawData = localStorage.getItem(BOOKMARK_IDS);

      if (!rawData) {
        return [];
      }

      const bookmarks = JSON.parse(rawData);

      if (!Array.isArray(bookmarks)) {
        console.warn('Invalid bookmarks data format, resetting...');
        localStorage.removeItem(BOOKMARK_IDS);
        return [];
      }

      const isValidBookmark = bookmark =>
        bookmark && typeof bookmark.id === 'number' && typeof bookmark.addTime === 'number';

      const validBookmarks = bookmarks.filter(isValidBookmark);

      if (validBookmarks.length !== bookmarks.length) {
        console.warn('Corrupted bookmarks found. Removing invalid entries...');
        localStorage.setItem(BOOKMARK_IDS, JSON.stringify(validBookmarks));
      }

      return validBookmarks;
    } catch (error) {
      console.error('Error getting bookmarks:', error);
      localStorage.removeItem(BOOKMARK_IDS);
      return [];
    }
  }

  addBookmark(bookmarkId) {
    if (!this.hasBookmark(bookmarkId)) {
      const bookmarks = this.getBookmarks();

      bookmarks.push({id: Number(bookmarkId), addTime: Date.now()});
      localStorage.setItem(BOOKMARK_IDS, JSON.stringify(bookmarks));
      document.dispatchEvent(new CustomEvent(BOOKMARK_CUSTOM_EVENTS.ADD));
    }
  }

  removeBookmark(articleId) {
    const bookmarks = this.getBookmarks();

    localStorage.setItem(BOOKMARK_IDS, JSON.stringify(
      bookmarks.filter(bookmark => bookmark.id !== Number(articleId)))
    );

    document.dispatchEvent(new CustomEvent(BOOKMARK_CUSTOM_EVENTS.REMOVE));
  }

  async fetchBookmarkedArticles() {
    const bookmarks = this.getBookmarks();

    if (bookmarks.length === 0) {
      return [];
    }

    try {
      const articleCards = await customFetch({url: `articles?ids=${bookmarks.map(bookmark => bookmark.id).join(',')}`});
      const articleCardsMapped = articlesMapper(articleCards);
      const resultArticleCards = shallowMergeArrays(bookmarks, articleCardsMapped);

      resultArticleCards
        .sort(
          (resultArticleCard1, resultArticleCard2) => resultArticleCard2.addTime - resultArticleCard1.addTime
        );

      return resultArticleCards.map(article => ({
        ...article,
        image: {
          ...article.image,
          ...article.image.secondarySizes
        }
      }));
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}

const bookmarksService = new BookmarksService();

export {bookmarksService};
