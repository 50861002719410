import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

import {ARTICLE_CARD_BADGE_VARIANTS, ARTICLE_CARD_VARIANTS, Icon, ICON_NAMES} from '@nur-portal/ui-kit';

import {ArticleCard} from '../../website-ui/components/article-card';
import {ArticleCardBadge} from '../../website-ui/components/article-card-badge';
import {initArticleReactionsAndComments} from '../card-counters';

import {bookmarksService} from './bookmarks-service';
import {BOOKMARK_CUSTOM_EVENTS} from './constants';
import {EmptyBookmarks} from './empty-bookmarks';
import {migrateDataBase} from './migrate-bookmarks-data';

import styles from './bookmark-list.module.css';

const BookmarkList = () => {
  const [articles, setArticles] = useState([]);
  const {t} = useTranslation('bookmarks');

  const getArticleList = async () => {
    const nextArticles = await bookmarksService.fetchBookmarkedArticles();

    setArticles(nextArticles);
  };

  useEffect(() => {
    const initBookmarksList = async () => {
      // remove after one month 7 march 2025
      await migrateDataBase();
      await getArticleList();
    };

    initBookmarksList();
  }, []);

  useEffect(() => {
    initArticleReactionsAndComments('.js-bookmark-article');
  }, [articles.length]);

  useEffect(() => {
    document.addEventListener(BOOKMARK_CUSTOM_EVENTS.ADD, getArticleList);
    document.addEventListener(BOOKMARK_CUSTOM_EVENTS.OPEN_LIST, getArticleList);
    return () => {
      document.removeEventListener(BOOKMARK_CUSTOM_EVENTS.ADD, getArticleList);
      document.removeEventListener(BOOKMARK_CUSTOM_EVENTS.OPEN_LIST, getArticleList);
    };
  }, []);

  const handleOpenArticle = event => {
    event.preventDefault();

    document.location.href = event.target.href;
  };

  const handleCloseBookmarks = () => {
    document.dispatchEvent(new CustomEvent(BOOKMARK_CUSTOM_EVENTS.CLOSE_LIST));
  };

  const handleRemoveArticle = id => {
    bookmarksService.removeBookmark(id);
    getArticleList();
  };

  return (
    <>
      <div className={styles.head}>
        <div className={styles.title}>
          {
            t('list.title')}{articles.length > 0 && `: ${articles.length}`
          }
        </div>
        <button onClick={handleCloseBookmarks} className={styles.closeButton}>
          <Icon name={ICON_NAMES.CLOSE_30} />
        </button>
      </div>
      {articles.length === 0 && <EmptyBookmarks />}
      {
        articles.length > 0 &&
        <ul className={clsx(styles.list, 'js-bookmarks-list')}>
          {
            articles.map(bookmark =>
              (
                <li className={styles.item} key={bookmark.id}>
                  <ArticleCard
                    {...bookmark}
                    className="js-bookmark-article"
                    variant={ARTICLE_CARD_VARIANTS.SECONDARY}
                    onClick={handleOpenArticle}
                    onRemoveArticle={handleRemoveArticle}
                    articleBadgeComponent={(
                      <ArticleCardBadge variant={ARTICLE_CARD_BADGE_VARIANTS.SECTION}>
                        {bookmark.category}
                      </ArticleCardBadge>
                    )}
                  />
                </li>
              )
            )
          }
        </ul>
      }
    </>
  );
};

export {BookmarkList};
