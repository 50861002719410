import {BOOKMARK_CUSTOM_EVENTS} from './constants';

class IndexedDBManager {
  constructor(dbName, storeName, version) {
    if (IndexedDBManager.instance) {
      return IndexedDBManager.instance;
    }

    this.dbName = dbName;
    this.storeName = storeName;
    this.version = version;
    this.db = null;
    this.listeners = {};

    IndexedDBManager.instance = this;
  }

  static getInstance(dbName, storeName, version) {
    if (!IndexedDBManager.instance) {
      IndexedDBManager.instance = new IndexedDBManager(dbName, storeName, version);
    }
    return IndexedDBManager.instance;
  }

  openDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.version);

      request.onupgradeneeded = event => {
        this.db = event.target.result;
        const objectStore = this.db.createObjectStore(this.storeName, {keyPath: 'id'});

        objectStore.createIndex('name', 'name', {unique: false});
      };

      request.onsuccess = event => {
        this.db = event.target.result;
        resolve(this.db);
      };

      request.onerror = event => {
        reject(event.target.errorCode);
      };
    });
  }

  addData(data) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const objectStore = transaction.objectStore(this.storeName);
      const request = objectStore.add(data);

      request.onsuccess = () => {
        document.dispatchEvent(new Event(BOOKMARK_CUSTOM_EVENTS.ADD));
        resolve(data);
      };

      request.onerror = event => {
        reject(event.target.errorCode);
      };
    });
  }

  getData(id) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readonly');
      const objectStore = transaction.objectStore(this.storeName);
      const request = objectStore.get(id);

      request.onsuccess = event => {
        resolve(event.target.result || null);
      };

      request.onerror = event => {
        reject(event.target.errorCode);
      };
    });
  }

  getAllData() {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([this.storeName], 'readonly');
      const objectStore = transaction.objectStore(this.storeName);
      const request = objectStore.openCursor();
      const data = [];

      request.onsuccess = event => {
        const cursor = event.target.result;

        if (cursor) {
          data.push(cursor.value);
          cursor.continue();
        } else {
          resolve(data);
        }
      };

      request.onerror = event => {
        reject(event.target.errorCode);
      };
    });
  }

  removeData(id) {
    return new Promise((resolve, reject) => {
      if (!this.db) {
        return reject('Database not opened');
      }

      const transaction = this.db.transaction([this.storeName], 'readwrite');
      const objectStore = transaction.objectStore(this.storeName);
      const request = objectStore.delete(id);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = event => {
        reject(event.target.error);
      };
    });
  }

  deleteDatabase() {
    return new Promise((resolve, reject) => {
      if (this.db) {
        this.db.close();
      }

      const deleteRequest = indexedDB.deleteDatabase(this.dbName);

      deleteRequest.onsuccess = () => {
        this.db = null;
        resolve();
      };

      deleteRequest.onerror = event => {
        console.error(event.target.error);
        reject(event.target.error);
      };
    });
  }
}

export {IndexedDBManager};
