// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "div.bookmark-tooltip-burger-button-module__tooltipContainer--1ThPc{top:calc(100% + 7px);right:auto;left:-11px;text-transform:none;text-align:left}@media screen and (min-width:756px){div.bookmark-tooltip-burger-button-module__tooltipContainer--1ThPc{top:calc(100% + 7px);right:auto;left:-32px}}@media screen and (min-width:1152px){div.bookmark-tooltip-burger-button-module__tooltipContainer--1ThPc{width:190px;top:-5px;right:auto;left:100%}}div.bookmark-tooltip-burger-button-module__tooltip--4lJEg{width:190px}div.bookmark-tooltip-burger-button-module__tooltip--4lJEg:before{top:-4px;left:17px}@media screen and (min-width:756px){div.bookmark-tooltip-burger-button-module__tooltip--4lJEg:before{top:-4px;left:38px}}@media screen and (min-width:1152px){div.bookmark-tooltip-burger-button-module__tooltip--4lJEg:before{top:19px;left:-4px}}", ""]);
// Exports
exports.locals = {
	"tooltipContainer": "bookmark-tooltip-burger-button-module__tooltipContainer--1ThPc",
	"tooltip": "bookmark-tooltip-burger-button-module__tooltip--4lJEg"
};
module.exports = exports;
