import isToday from 'date-fns/isToday/index.js';
import isYesterday from 'date-fns/isYesterday/index.js';
import kk from 'date-fns/locale/kk';
import ru from 'date-fns/locale/ru';
import {format} from 'date-fns-tz';

import {TIME_ZONE_ALMATY} from '../../constants/date';

const LOCALES = {
  www: ru,
  kaz: kk
};

const LOCALIZED_DAYS = {
  www: {
    today: '\'Сегодня, \'HH:mm',
    yesterday: '\'Вчера, \'HH:mm',
    withYear: 'd MMMM yyyy, HH:mm'
  },
  kaz: {
    yesterday: '\'Кеше, \'HH:mm\'',
    today: '\'Бүгін, \'HH:mm',
    withYear: 'd MMMM yyyy, HH:mm'
  }
};

const LOCALIZED_DAYS_WITH_FULL_DATE = {
  www: {
    today: '\'Сегодня, \'dd MMMM yyyy, HH:mm',
    yesterday: '\'Вчера, \'dd MMMM yyyy, HH:mm',
    withYear: 'd MMMM yyyy, HH:mm'
  },
  kaz: {
    yesterday: '\'Кеше, \'dd MMMM yyyy, HH:mm',
    today: '\'Бүгін, \'dd MMMM yyyy, HH:mm',
    withYear: 'd MMMM yyyy, HH:mm'
  }
};

const getDateWithTimezone = (utcDate, timeZone) => {
  const parts = new Intl.DateTimeFormat('en-US', {
    timeZone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false
  }).formatToParts(new Date(utcDate));

  const dateValues = Object.fromEntries(parts.map(({type, value}) => [type, value]));

  return new Date(
    Number(dateValues.year),
    Number(dateValues.month) - 1,
    Number(dateValues.day),
    Number(dateValues.hour),
    Number(dateValues.minute),
    Number(dateValues.second),
  );
};

const getFormattedDate = (date, locale = 'www') => {
  const dateWithTimeZone = getDateWithTimezone(date, TIME_ZONE_ALMATY);

  if (dateWithTimeZone === 'Invalid date' || !(dateWithTimeZone instanceof Date)) {
    return '';
  }

  const dateFormatOptions = {locale: LOCALES[locale]};
  const dateFormat = LOCALIZED_DAYS[locale];

  if (isToday(dateWithTimeZone)) {
    return format(dateWithTimeZone, dateFormat.today, dateFormatOptions);
  }

  if (isYesterday(dateWithTimeZone)) {
    return format(dateWithTimeZone, dateFormat.yesterday, dateFormatOptions);
  }

  return format(dateWithTimeZone, dateFormat.withYear, dateFormatOptions);
};

const getFormattedFullDateWithLocale = (date, locale = 'www') => {
  const dateNow = getDateWithTimezone(date, TIME_ZONE_ALMATY);

  if (isToday(dateNow)) {
    return format(dateNow, LOCALIZED_DAYS_WITH_FULL_DATE[locale].today, {locale: LOCALES[locale]});
  }
  if (isYesterday(dateNow)) {
    return format(dateNow, LOCALIZED_DAYS_WITH_FULL_DATE[locale].yesterday, {locale: LOCALES[locale]});
  }

  return format(dateNow, LOCALIZED_DAYS_WITH_FULL_DATE[locale].withYear, {locale: LOCALES[locale]});
};

const getTimeStamp = date => Math.floor(new Date(date).getTime() / 1000);

export {getTimeStamp, getFormattedDate, getFormattedFullDateWithLocale};
