// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".bookmark-list-module__head--2SbdO{justify-content:space-between}.bookmark-list-module__closeButton--3m4Or,.bookmark-list-module__head--2SbdO{display:flex;align-items:center}.bookmark-list-module__closeButton--3m4Or{width:30px;height:30px;justify-content:center;color:#212121;background-color:transparent;border:0;outline:0;cursor:pointer}.bookmark-list-module__title--JgNV4{font:700 21px/1.15 Arial,Roboto,Helvetica,sans-serif;letter-spacing:-.02em;color:#212121}.bookmark-list-module__list--3Hyyg{display:grid;grid-template-columns:1fr;grid-template-rows:min-content}@media screen and (min-width:756px){.bookmark-list-module__list--3Hyyg{grid-column-gap:30px;grid-row-gap:30px}}@media screen and (min-width:1152px){.bookmark-list-module__list--3Hyyg{grid-template-columns:1fr 1fr;grid-column-gap:40px;grid-row-gap:40px}}.bookmark-list-module__item--2C7ts{padding:0 0 15px;margin:0 0 15px;position:relative;border-bottom:1px solid #e2e2e2}.bookmark-list-module__item--2C7ts:last-child{padding:0;margin:0;border-bottom:0}@media screen and (min-width:1152px){.bookmark-list-module__item--2C7ts{padding:0;margin:0;border-bottom:0}.bookmark-list-module__item--2C7ts:after,.bookmark-list-module__item--2C7ts:before{position:absolute;background-color:#e2e2e2;content:\"\"}.bookmark-list-module__item--2C7ts:after{width:1px;height:100%;top:0;right:-20px}.bookmark-list-module__item--2C7ts:before{width:100%;height:1px;bottom:-20px;left:0}.bookmark-list-module__item--2C7ts:nth-child(2n):before{width:calc(100% + 40px);left:-40px}.bookmark-list-module__item--2C7ts:nth-child(2n):after{content:none}}@supports not (display:grid){.bookmark-list-module__list--3Hyyg{display:flex;flex-direction:column;gap:15px}@media screen and (min-width:1152px){.bookmark-list-module__list--3Hyyg{gap:20px}}}", ""]);
// Exports
exports.locals = {
	"head": "bookmark-list-module__head--2SbdO",
	"closeButton": "bookmark-list-module__closeButton--3m4Or",
	"title": "bookmark-list-module__title--JgNV4",
	"list": "bookmark-list-module__list--3Hyyg",
	"item": "bookmark-list-module__item--2C7ts"
};
module.exports = exports;
