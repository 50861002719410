import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {existsCookie, setCookie} from '../../services/cookie';

import {BookmarkTooltip} from './bookmark-tooltip';
import {
  BOOKMARK_CUSTOM_EVENTS,
  BOOKMARKS_SIDEBAR_TOOLTIP_COOKIE,
  THIRTY_DAYS_IN_HOURS
} from './constants';

import styles from './bookmark-tooltip-burger-button.module.css';

const BookmarkTooltipBurgerButton = () => {
  const [isShowModal, setShowModal] = useState(false);
  const {t} = useTranslation('bookmarks');

  const handleCloseTooltip = () => {
    setShowModal(false);
    setCookie(BOOKMARKS_SIDEBAR_TOOLTIP_COOKIE, true, THIRTY_DAYS_IN_HOURS);
  };

  const bookmarksChanges = async () => {
    if (existsCookie(BOOKMARKS_SIDEBAR_TOOLTIP_COOKIE)) {
      return;
    }

    setShowModal(true);
  };

  useEffect(() => {
    document.addEventListener(BOOKMARK_CUSTOM_EVENTS.ADD, bookmarksChanges);
    return () => {
      document.removeEventListener(BOOKMARK_CUSTOM_EVENTS.ADD, bookmarksChanges);
    };
  }, []);

  if (!isShowModal) {
    return null;
  }

  return (
    <BookmarkTooltip
      onClose={handleCloseTooltip}
      onAnimationEnd={handleCloseTooltip}
      className={styles.tooltip}
      containerClassName={styles.tooltipContainer}
      text={t('openBookmarkListTooltip')}
    />
  );
};

export {BookmarkTooltipBurgerButton};
