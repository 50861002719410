import {getFormattedDate} from '../../website-ui/utils/date';

const IMAGES_SIZES = {
  primary: [
    ['1200x675', '1200w'],
    ['1120x630', '1120w'],
    ['720x405', '720w'],
    ['560x315', '560w']
  ],
  secondary: [
    ['720x405', '720w'],
    ['560x315', '560w'],
    ['272x153', '272w'],
    ['176x99', '176w']
  ]
};

const CDN_BASE_URL = 'https://cdn.nur.kz';
const IMAGES_BASE_URL = `${CDN_BASE_URL}/images/`;
const IMAGE_SIZES_SECONDARY =
  '(max-width: 517px) 97px, (max-width: 755px) 118px, (max-width: 1025px) 560px, 560px';

const getImagesSrcSet = (hash, sizes, format) =>
  sizes.map(([size, width]) => `${IMAGES_BASE_URL}${size}/${hash}.${format} ${width}`);

const articleMapper = (article, locale) => {
  const formattedDate = getFormattedDate(article.date, locale);

  return {
    ...article,
    formattedDate,
    image: {
      ...article.image,
      loading: 'lazy',
      src: {
        webp: `${IMAGES_BASE_URL}720x405${article.image.hashKey}.webp`,
        jpeg: `${IMAGES_BASE_URL}720x405/${article.image.hashKey}.jpeg`
      },
      srcset: {
        webp: getImagesSrcSet(article.image.hashKey, IMAGES_SIZES.secondary, 'webp').join(','),
        jpeg: getImagesSrcSet(article.image.hashKey, IMAGES_SIZES.secondary, 'jpeg').join(',')
      },
      sizes: IMAGE_SIZES_SECONDARY
    }
  };
};

const articlesMapper = articles => articles.map(article => articleMapper(article, window.Site.targeting.section));

export {articleMapper, articlesMapper};
