import React from 'react';
import {render} from 'react-dom';

import {disableScroll, enableScroll} from '../../services/scroll-helpers';

import {BookmarkList} from './bookmark-list';
import {BookmarkTooltipBurgerButton} from './bookmark-tooltip-burger-button';
import {BOOKMARK_CUSTOM_EVENTS} from './constants';

const renderBookmarksTooltipOnBurgerButton = rootElement => {
  if (!rootElement) {
    return;
  }

  render(<BookmarkTooltipBurgerButton />, rootElement);
};

const renderBookmarksList = rootElement => {
  if (!rootElement) {
    return;
  }

  render(<BookmarkList />, rootElement);
};

const toggleBookmarkModal = () => {
  const bookmarksModalElement = document.querySelector('.js-bookmarks-modal');

  if (!bookmarksModalElement) {
    return;
  }

  if (bookmarksModalElement.classList.contains('bookmarks-modal--open')) {
    bookmarksModalElement.classList.remove('bookmarks-modal--open');
    enableScroll();
  } else {
    bookmarksModalElement.classList.add('bookmarks-modal--open');
    disableScroll();
  }
};

const initBookmarks = () => {
  const bookmarksModalElement = document.querySelector('.js-bookmarks-modal');

  if (!bookmarksModalElement) {
    return;
  }

  document.addEventListener(BOOKMARK_CUSTOM_EVENTS.OPEN_LIST, toggleBookmarkModal);
  document.addEventListener(BOOKMARK_CUSTOM_EVENTS.CLOSE_LIST, toggleBookmarkModal);

  const overlayElement = document.querySelector('.js-bookmarks-modal-overlay');

  if (overlayElement) {
    overlayElement.addEventListener('click', toggleBookmarkModal);
  }
};

export {renderBookmarksTooltipOnBurgerButton, renderBookmarksList, initBookmarks};
