import {bookmarksService} from './bookmarks-service';
import {BOOKMARKS_DB, BOOKMARKS_STORE} from './constants';
import {IndexedDBManager} from './indexed-db-manager';

const checkDatabaseExists = async () => {
  if (typeof window.indexedDB.databases === 'undefined') {
    return false;
  }

  const databases = await window.indexedDB.databases();

  return databases.map(db => db.name).includes(BOOKMARKS_DB);
};

const migrateDataBase = async () => {
  try {
    const isDbExist = await checkDatabaseExists();

    if (!isDbExist) {
      console.warn(`Database "${BOOKMARKS_DB}" does not exist. Skipping execution.`);
      return;
    }

    const dbManager = IndexedDBManager.getInstance(BOOKMARKS_DB, BOOKMARKS_STORE, 1);

    await dbManager.openDB();

    const bookmarks = await dbManager.getAllData();

    bookmarks.sort((bookmark1, bookmark2) => bookmark2.addTime - bookmark1.addTime);

    bookmarks.forEach(bookmark => bookmarksService.addBookmark(bookmark.id));

    await dbManager.deleteDatabase();
  } catch (error) {
    console.error(error);
  }
};

export {migrateDataBase};
