import React from 'react';
import clsx from 'clsx';

import {ARTICLE_CARD_BADGE_VARIANTS} from '@nur-portal/ui-kit';

const ArticleCardBadge = ({children, className, variant}) => (
  <div
    className={clsx([
      'article-card-badge',
      className,
      {
        'article-card-badge--section': variant === ARTICLE_CARD_BADGE_VARIANTS.SECTION,
        'article-card-badge--section-dark': variant === ARTICLE_CARD_BADGE_VARIANTS.SECTION_DARK,
        'article-card-badge--live': variant === ARTICLE_CARD_BADGE_VARIANTS.LIVE,
        'article-card-badge--food': variant === ARTICLE_CARD_BADGE_VARIANTS.FOOD
      }
    ])}
  >
    {children}
  </div>
);

export {ArticleCardBadge};
